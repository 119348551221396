<template>
  <div>
    <ics-page-inner v-if="isConfirm" title="还款信息">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <el-col :span="12">
          <el-form-item label="还款金额(元)">
            <p>{{ utils.moneyFormat(payInfo.repaymentAmount, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="还款期限">
            <p>{{ utils.dateFormat(payInfo.loanEnd) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="本金">
            <p>{{ utils.moneyFormat(payInfo.theCapital, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="利息">
            <p>{{ utils.moneyFormat(payInfo.theInterest, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="还款状态">
            <p>{{ utils.statusFormat(payInfo.recordStatus, 'recordStatus') }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="payInfo.pay" :span="12">
          <el-form-item label="还款凭证">
            <p><a href="javascript:" class="text-btn" @click="utils.downloadP('repay', payInfo.pay.url)">{{ payInfo.pay.name || '-' }}</a></p>
          </el-form-item>
        </el-col>
        <el-col />
        <el-col :span="12">
          <el-form-item label="收款日期" prop="collectionDate" :rules="{ required: true, message: '请选择收款日期', trigger: 'change' }">
            <el-date-picker
              v-model="appForm.collectionDate"
              type="date"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 100%"
              placeholder="选择日期"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-col>
        <el-col />
        <el-col :span="12">
          <el-form-item label="收款凭证" prop="files" :rules="{ required: true, message: '请上传收款凭证', trigger: 'change' }">
            <p>
              <el-button type="primary" @click="clickDialog">
                点击上传
              </el-button>
            </p>
            <p v-for="(item, index) in appForm.files" :key="index">
              <a href="javascript:" class="text-btn" @click="utils.downloadP('repay', item.url)">{{ item.name }}</a>
              <a href="javascript:" class="text-btn danger" @click="deleteFile(index)"><i class="el-icon-delete" /></a>
            </p>
          </el-form-item>
        </el-col>
        <el-col :span="24" style="display: flex;justify-content:center;margin-bottom: 50px;padding-top: 30px;">
          <el-button @click="closeInfo">
            关 闭
          </el-button>
          <debounce-button :loading="loadingSubmit.submit" type="primary" style="margin-right: 10px;" @click="submitForms">
            确 认
          </debounce-button>
        </el-col>
      </el-form>
    </ics-page-inner>
    <ics-page-inner v-if="!isConfirm" title="还款信息">
      <el-form label-width="180px" :label-suffix="constants.labelSuffix">
        <el-col :span="12">
          <el-form-item label="还款金额(元)">
            <p>{{ utils.moneyFormat(payInfo.repaymentAmount, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="还款期限">
            <p>{{ utils.dateFormat(payInfo.loanEnd) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="本金">
            <p>{{ utils.moneyFormat(payInfo.theCapital, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="利息">
            <p>{{ utils.moneyFormat(payInfo.theInterest, 2) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="还款状态">
            <p>{{ utils.statusFormat(payInfo.recordStatus, 'recordStatus') }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="payInfo.pay" :span="12">
          <el-form-item label="还款凭证">
            <p><a href="javascript:" class="text-btn" @click="utils.downloadP('repay', payInfo.pay.url)">{{ payInfo.pay.name || '-' }}</a></p>
          </el-form-item>
        </el-col>
        <el-col />
        <el-col :span="12">
          <el-form-item label="收款日期">
            <p>{{ utils.dateFormat(payInfo.collectionDate) }}</p>
          </el-form-item>
        </el-col>
        <el-col />
        <el-col v-if="payInfo.co" :span="12">
          <el-form-item label="收款凭证">
            <p><a href="javascript:" class="text-btn" @click="utils.downloadP('repay', payInfo.co.url)">{{ payInfo.co.name || '-' }}</a></p>
          </el-form-item>
        </el-col>
      </el-form>
    </ics-page-inner>
    <ics-common-upload-inner :upload-dialog="dialog" bucket="repay" @onSuccessFile="onSuccessFile" />
  </div>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsCommonUploadInner from '@/components/common-upload-inner'
export default {
  name: 'IcsPayBackInner',
  components: { IcsCommonUploadInner },
  mixins: [routeEnterMixin],
  props: {
    isConfirm: {
      type: Boolean,
      default: false
    },
    payType: {
      type: String,
      default: ''
    },
    businessId: {
      type: [String, Number],
      default: ''
    },
    loadingSubmit: {
      type: Object,
      default() {
        return {}
      }
    },
    planDetail: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data () {
    return {
      fileUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/customer/b/v1/file/upload?token=' + this.$store.state.token,
      appForm: {
        collectionDate: '',
        files: []
      },
      dialog: {
        visible: false,
        title: '上传凭证'
      },
      payInfo: {},
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {}
    }
  },
  watch: {
    businessId: {
      handler (val) {
        if (val) {
          this.getDetail()
        }
      },
      immediate: true
    },
  },
  created () {
  },
  methods: {
    getDetail() {
      this.api.payBack.recordDetails(this.businessId).then(result => {
        const data = result.data.data.repaymentPlanRecord
        result.data.data.files.forEach(item => {
          if (item.fileType === 'pay') {
            data.pay = item || {}
          }
          if (item.fileType === 'co') {
            data.co = item || {}
          }
        })
        this.payInfo = data
      })
    },
    clickDialog () {
      this.dialog.visible = true
    },
    onSuccessFile(response) {
      const data = this._.cloneDeep(response.data)
      data.url = data.path
      data.fileType = 'co'
      this.appForm.files = [data]
    },
    deleteFile (index) {
      this.appForm.files.splice(index, 1)
    },
    previewFile (file) {
      this.utils.downloadFile(file.url, file.name)
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        const data = this._.cloneDeep(this.appForm)
        data.id = this.payInfo.id
        this.$emit('submit', data)
      })
    },
    closeInfo () {
      this.appForm.files = []
      this.appForm.collectionDate = ''
      this.loadingSubmit.submit = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
